'use strict';

var OperationGroup = module.exports = function (tag, description, externalDocs, operation) {
  this.description = description;
  this.externalDocs = externalDocs;
  this.name = tag;
  this.operation = operation;
  this.operationsArray = [];
  this.path = tag;
  this.tag = tag;
};

OperationGroup.prototype.sort = function () {

};

